import { Route, Routes } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { lazy, useEffect } from 'react';
// import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import UserToken from '../components/UserToken';
import CashCollection from '../views/blueteam/payments/CashCollection';
import ContactEnquiry from '../views/blueteam/website/ContactEnquiry';
import BookingEnquiry from '../views/blueteam/website/BookingEnquiry';
import Teams from '../views/blueteam/teams/Index';
import MapFilterForm from '../views/blueteam/location/MapFilterForm';
import Editquote from '../views/blueteam/quotation/Editquote';
import Brands from '../views/blueteam/store/Brands';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
/***** Pages ****/

const Classic = Loadable(lazy(() => import('../views/dashboards/Classic')));
const LoginFormik = Loadable(lazy(() => import('../views/blueteam/Login')));

// users
const AllUsers = Loadable(lazy(() => import('../views/blueteam/AllUsers')));
const FilterUser = Loadable(lazy(() => import('../views/blueteam/FilterUser')));
const NoResponse = Loadable(lazy(() => import('../views/blueteam/NoResponse')));
const NotRetrieved = Loadable(lazy(() => import('../views/blueteam/NotRetrieved')));
const RetrievedUser = Loadable(lazy(() => import('../views/blueteam/RetrievedUser')));
const UserAnalysis = Loadable(lazy(() => import('../views/blueteam/users/userAnalysis')));

//Driver Helper
const DriverHelper = Loadable(lazy(() => import('../views/blueteam/driver-helper/driverHelper')));

//Reports
const Reports = Loadable(lazy(() => import('../views/blueteam/reports/Reports')));
const WorkStation = Loadable(lazy(() => import('../views/blueteam/reports/WorkStation')));
const DeleteSystem = Loadable(lazy(() => import('../views/blueteam/reports/DeleteSystem')));
const Cafu = Loadable(lazy(() => import('../views/blueteam/reports/Cafu')));

//Bonus
const Bonus = Loadable(lazy(() => import('../views/blueteam/bonus/Bonus')));

//location
const MapLocation = Loadable(lazy(() => import('../views/blueteam/location/Index')));
const TodayMapLocation = Loadable(lazy(() => import('../views/blueteam/location/IndexLive')));
const BookingMonthlyAnalysis = Loadable(
  lazy(() => import('../views/blueteam/location/BookingMonthlyAnalysis')),
);

//Service
const AddService = Loadable(lazy(() => import('../views/blueteam/services/AddService')));
const ViewServices = Loadable(lazy(() => import('../views/blueteam/services/ViewServices')));
const EditServices = Loadable(lazy(() => import('../views/blueteam/services/EditServices')));
const SortWallet = Loadable(lazy(() => import('../views/blueteam/driver-helper/SortWallet')));
const QuoteGenerator = Loadable(lazy(() => import('../views/blueteam/quotation/quote-generator')));
const SlotBooking = Loadable(lazy(() => import('../views/blueteam/booking/SlotBooking')));
const UserLogs = Loadable(lazy(() => import('../views/blueteam/logs/UserLogs')));
const UserNewLogs = Loadable(lazy(() => import('../views/blueteam/logs/UserNewLogs')));
const EditBookingNew = Loadable(lazy(() => import('../views/blueteam/booking/EditBookingNew')));
const BookingQuotePdf = Loadable(lazy(() => import('../views/blueteam/quotation/BookingQuotePdf')));
const Complaints = Loadable(lazy(() => import('../views/blueteam/complaints/Index')));
const ViewComplaints = Loadable(lazy(() => import('../views/blueteam/complaints/ViewComplaints')));
const AddComplaints = Loadable(lazy(() => import('../views/blueteam/complaints/AddComplaints')));
const Consent = Loadable(lazy(() => import('../views/blueteam/workstation/Consent')));
const Schedule = Loadable(lazy(() => import('../views/blueteam/schedule/Index')));
const WorkStationView = Loadable(lazy(() => import('../views/blueteam/workstation/Index')));
const Quotation = Loadable(lazy(() => import('../views/blueteam/quotation/Quotation')));
const Expense = Loadable(lazy(() => import('../views/blueteam/expense/Index')));
const EditBookingWorkstation = Loadable(
  lazy(() => import('../views/blueteam/workstation/EditBooking')),
);
const GetBookingDetailById = Loadable(
  lazy(() => import('../views/blueteam/booking/GetBookingDetailById')),
);
const ViewQuotation = Loadable(lazy(() => import('../views/blueteam/quotation/ViewQuotation')));
const AudioRecorder = Loadable(lazy(() => import('../views/blueteam/booking/AudioRecorder')));
const Issues = Loadable(lazy(() => import('../views/blueteam/servicemanapp/Issues')));
const Inspection = Loadable(lazy(() => import('../views/blueteam/servicemanapp/Inspection')));
const InspectionDetail = Loadable(
  lazy(() => import('../views/blueteam/servicemanapp/InspectionDetail')),
);
const StoreList = Loadable(lazy(() => import('../views/blueteam/store/StoreList')));
const AddProduct = Loadable(lazy(() => import('../views/blueteam/store/AddProduct')));
const EditProduct = Loadable(lazy(() => import('../views/blueteam/store/EditProduct')));
const TransferList = Loadable(lazy(() => import('../views/blueteam/store/TransferList')));
const QuantityAlert = Loadable(lazy(() => import('../views/blueteam/store/QuantityAlert')));
const Recharge = Loadable(lazy(() => import('../views/blueteam/recharge/Index')));
const SaleReport = Loadable(lazy(() => import('../views/blueteam/reports/SaleReport')));
const TeamAvailibilityChart = Loadable(
  lazy(() => import('../views/blueteam/driver-helper/TeamAvailibilityChart')),
);
const AddSale = Loadable(lazy(() => import('../views/blueteam/store/AddSale')));
const ViewSales = Loadable(lazy(() => import('../views/blueteam/store/ViewSales')));
const EditSale = Loadable(lazy(() => import('../views/blueteam/store/EditSale')));
const ContactList = Loadable(lazy(() => import('../views/blueteam/users/ContactList')));

const PackageServices = Loadable(lazy(() => import('../views/blueteam/packages/Services')));
const EditExpense = Loadable(lazy(() => import('../views/blueteam/expense/EditExpense')));
const FreeSlots = Loadable(lazy(() => import('../views/blueteam/driver-helper/FreeSlots')));
const BtCar = Loadable(lazy(() => import('../views/blueteam/driver-helper/BtCar')));
const DeleteBookings = Loadable(lazy(() => import('../views/blueteam/booking/DeleteBookings')));
const AllCars = Loadable(lazy(() => import('../views/blueteam/store/AllCars')));
const AdditionalServices = Loadable(lazy(() => import('../views/blueteam/packages/Additional')));

const Routernew = () => {
  const { token, setToken } = UserToken();
  const logout = () => {
    localStorage.clear();
    window.location.reload();
    // Additional logout logic (e.g., redirect to login page)
  };
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        try {
          const decoded = jwt.verify(token, 'blueteamisbest');

          // Check if the token has expired
          const isTokenExpired = decoded.exp < Date.now() / 1000;

          if (isTokenExpired) {
            logout();
          }
        } catch (error) {
          logout();
          console.error('Error decoding token:', error);
        }
      }
    };

    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 1000 * 60); // Check every minute

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [token]);
  if (!token) {
    return <LoginFormik setToken={setToken} />;
  }
  return (
    <div>
      <Routes>
        <Route path="/" element={<FullLayout></FullLayout>}>
          <Route path="/" element={<Classic />} />
          <Route path="/dashboard" name="dashboard" element={<Classic />}></Route>
          <Route path="/AllUsers" name="AllUsers" element={<AllUsers />}></Route>
          <Route path="/userAnalysis" name="UserAnalysis" element={<UserAnalysis />}></Route>
          <Route path="/filterUser" name="filterUser" element={<FilterUser />}></Route>
          <Route path="/NoResponse" name="NoResponse" element={<NoResponse />}></Route>
          <Route path="/NotRetrieved" name="NotRetrieved" element={<NotRetrieved />}></Route>
          <Route path="/RetrievedUser" name="RetrievedUser" element={<RetrievedUser />}></Route>
          <Route path="/driverHelper" name="driverHelper" element={<DriverHelper />}></Route>
          <Route path="/reports" name="reports" element={<Reports />}></Route>
          <Route path="/workstationreports" name="wreports" element={<WorkStation />}></Route>
          <Route path="/bonus" name="bonus" element={<Bonus />}></Route>
          <Route path="/deletesystem" name="deletesystem" element={<DeleteSystem />}></Route>
          <Route path="/cashcollection" name="cashcollection" element={<CashCollection />}></Route>
          <Route path="/maplocation" name="maplocation" element={<MapLocation />}></Route>
          <Route path="/addservices" name="addservices" element={<AddService />}></Route>
          <Route path="/viewservices" name="viewservices" element={<ViewServices />}></Route>
          <Route path="/editService" name="editService" element={<EditServices />}></Route>
          <Route path="/contactEnquiry" name="contactEnquiry" element={<ContactEnquiry />}></Route>
          <Route path="/bookingEnquiry" name="bookingEnquiry" element={<BookingEnquiry />}></Route>
          <Route path="/SortWallet" name="bookingEnquiry" element={<SortWallet />}></Route>
          <Route path="/SortWallet" name="bookingEnquiry" element={<SortWallet />}></Route>
          <Route path="/QuoteGenerator" name="QuoteGenerator" element={<QuoteGenerator />}></Route>
          <Route path="/slotBooking" name="slotBooking" element={<SlotBooking />}></Route>
          <Route path="/userLogs" name="userLogs" element={<UserLogs />}></Route>
          <Route path="/userNewLogs" name="userNewLogs" element={<UserNewLogs />}></Route>
          <Route path="/editBooking/:id" name="editBooking" element={<EditBookingNew />}></Route>
          <Route path="/teams" name="teams" element={<Teams />}></Route>
          <Route path="/map" name="map" element={<MapFilterForm />}></Route>
          <Route path="/quoteInvoice" name="quoteInvoice" element={<BookingQuotePdf />}></Route>
          <Route path="/todaymap" name="todaymap" element={<TodayMapLocation />}></Route>
          <Route
            path="/bookinganalysis"
            name="todaymap"
            element={<BookingMonthlyAnalysis />}
          ></Route>

          <Route path="/complaints" name="complaints" element={<Complaints />}></Route>
          <Route path="/viewComplaints" name="ViewComplaints" element={<ViewComplaints />}></Route>
          <Route path="/addComplaints" name="AddComplaints" element={<AddComplaints />}></Route>
          <Route path="/consent" name="Consent" element={<Consent />}></Route>
          <Route path="/Schedule" name="Schedule" element={<Schedule />}></Route>
          <Route
            path="/workstationview"
            name="WorkStationView"
            element={<WorkStationView />}
          ></Route>
          <Route path="/quote" name="quote" element={<Quotation />}></Route>
          <Route path="/expense" name="expense" element={<Expense />}></Route>
          <Route
            path="/workstationedit/:id"
            name="EditBookingWorkstation"
            element={<EditBookingWorkstation />}
          ></Route>
          <Route
            path="/getBookingDetailById"
            name="GetBookingDetailById"
            element={<GetBookingDetailById />}
          ></Route>
          <Route path="/viewquote" name="viewQuotation" element={<ViewQuotation />}></Route>
          <Route path="/editquote/:id" name="Editquote" element={<Editquote />}></Route>
          <Route path="/cafu" name="cafu" element={<Cafu />}></Route>
          <Route path="/audiorecorder" name="AudioRecorder" element={<AudioRecorder />}></Route>

          <Route path="/issues" name="Issues" element={<Issues />}></Route>
          <Route path="/inspection" name="Inspection" element={<Inspection />}></Route>

          <Route
            path="/inspectionDetail/:id"
            name="InspectionDetail"
            element={<InspectionDetail />}
          ></Route>
          <Route path="/storelist" name="StoreList" element={<StoreList />}></Route>
          <Route path="/addproduct" name="AddProduct" element={<AddProduct />}></Route>
          <Route path="/editproduct/:id" name="EditProduct" element={<EditProduct />}></Route>
          <Route path="/transferlist" name="transferlist" element={<TransferList />}></Route>
          <Route path="/quantityalert" name="QuantityAlert" element={<QuantityAlert />}></Route>
          <Route path="/recharge" name="recharge" element={<Recharge />}></Route>
          <Route path="/salereport" name="SaleReport" element={<SaleReport />}></Route>
          <Route
            path="/teamavailibility"
            name="TeamAvailibilityChart"
            element={<TeamAvailibilityChart />}
          ></Route>
          <Route path="/addsale" name="AddSale" element={<AddSale />}></Route>
          <Route path="/viewsale" name="ViewSales" element={<ViewSales />}></Route>
          <Route path="/editsale/:id" name="EditSale" element={<EditSale />}></Route>
          <Route path="/contactlist" name="ContactList" element={<ContactList />}></Route>
          <Route path="/packages" name="PackageServices" element={<PackageServices />}></Route>
          <Route path="/editExpense" name="EditExpense" element={<EditExpense />}></Route>
          <Route path="/freeslots" name="FreeSlots" element={<FreeSlots />}></Route>
          <Route path="/btcar" name="BtCar" element={<BtCar />}></Route>
          <Route path="/delete-booking" name="DeleteBookings" element={<DeleteBookings />}></Route>
          <Route path="/allcars" name="AllCars" element={<AllCars />}></Route>
          <Route path="/brands" name="Brands" element={<Brands />}></Route>
          <Route
            path="/additional-services"
            name="AdditionalServices"
            element={<AdditionalServices />}
          ></Route>
        </Route>
      </Routes>
    </div>
  );
};

export default Routernew;
